import Vue from 'vue'
import VModal from 'vue-js-modal'
import VueCookies from 'vue-cookies'

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin


import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import store from './store/index'
import router from './router/index'
import vuetify from './plugins/vuetify';

Vue.use(Loading);


Vue.use(VModal, {
  dynamic: true
})

Vue.use(VueCookies)
VueCookies.config('2d')

//axios.defaults.headers.common['X-CSRFToken'] = document.getElementsByName("csrfmiddlewaretoken")[0].value;

//const base_url = '/';
/*
const instance = axios.create({
  baseURL: base_url
});*/
if (document.getElementsByName('csrfmiddlewaretoken').length > 0) {
  axios.defaults.headers.common['x-csrftoken'] = document.getElementsByName('csrfmiddlewaretoken')[0].value
}
Vue.use(VueAxios, axios)

Vue.prototype.$urls = window.Urls

Vue.prototype.$setCookie = (cookieName, cookieValue) => {
  Vue.prototype.$cookies.set(cookieName, cookieValue);
}

Vue.prototype.$getCookie = (cookieName) => {
  return Vue.prototype.$cookies.get(cookieName);
}


Vue.config.productionTip = false
//export default instance


new Vue({
  store,
  router,

  created() {
  },

  vuetify,
  render: h => h(App)
}).$mount('#app')
