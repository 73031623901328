import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#14245F',
            'thon-blue': '#14245F',
            'thon-gold': '#FFC915',
          },
          dark: {
            primary: '#14245F',
            'thon-blue': '#14245F',
            'thon-gold': '#FFC915',
          },
        },
      },
    buttons: {
        capitalize: false,
    },
    icons: {
        iconfont: 'mdi'
    }
});
