<template>
  <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      class="lighten-1 white--text text-center"
      width="100%"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon[1]"
          class="mx-4 white--text"
          icon
          :href="icon[0]"
          target="_blank"
        >
          <v-icon size="24px">
            {{ icon[1] }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
    1-800-392-THON
    </v-card-text>
      <v-card-text class="white--text pt-0">
      227D HUB-Robeson Center<br> University Park, PA 16802
      </v-card-text>
      
    <v-card-text class="pt-0">
    <a class="text-decoration-none white--text" target="_blank" href="https://www.psu.edu/web-privacy-statement/">
    Privacy Statement & Legal
    </a>
    </v-card-text>
      <v-divider></v-divider>

      <v-card-text>
      Site Designed & Maintained by THON Technology © {{ new Date().getFullYear() }}<br>Hosting provided by Amazon Web Services Inc.
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        ['https://www.instagram.com/pennstatethon/', 'mdi-instagram'],
        ['https://www.facebook.com/PennStateTHON', 'mdi-facebook'],
        ['https://www.linkedin.com/company/penn-state-dance-marathon/', 'mdi-linkedin'],
        ['https://twitter.com/THON', 'mdi-twitter'],
        ['https://www.youtube.com/user/thontech', 'mdi-youtube'],
      ],
    }),
  }
</script>