<template>
  <v-snackbar v-if="notification" v-model="display">
    {{ notification }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="red"
        text
        v-bind="attrs"
        @click="display = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['notification'])
  },
  data() {
    return {
      display: false
    }
  },
  watch: {
    notification(newValue) {
      if (newValue) {
        this.display = true;
      }
    },

    display(newValue) {
      if (!newValue) {
        this.$store.commit('UPDATE_NOTIFICATION', "");
      }
    }
  }
}
</script>

<style>

</style>