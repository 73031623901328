import * as apiCalls from './rootApi';

const actions = {
    async getAllGalleries(params, payload) {
        try {
            return await apiCalls.getAllGalleries(payload);
        }
        catch (e) {
            console.error(e);
        }
    },
    async getGallery(params, payload) {
        try {
            return await apiCalls.getGallery(payload);
        }
        catch (e) {
            console.error(e);
        }
    },
    async createGallery(params, payload) {
        try {
            return await apiCalls.createGallery(payload);
        }
        catch (e) {
            console.error(e);
        }
    },
}

export default actions