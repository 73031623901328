//import instance from '@/main'

//const urlPrefix = '/api/'

const getAllGalleries = async() => {
    let response = {}//await instance.get(`${urlPrefix}galleries`, { params: payload });
    return response;
}

const getGallery = async() => {
    let response = {}//await instance.get(`${urlPrefix}galleries/${payload.id}`);
    return response;
}

const createGallery = async() => {
    let response = {}//await instance.post(`${urlPrefix}galleries`, { params: payload });
    return response;
}

export {
    getAllGalleries,
    getGallery,
    createGallery
}