import Vue from 'vue'
import Vuex from 'vuex'
import actions from './root/rootActions' 
import getters from './root/rootGetters'
//import mutations from './root/rootMutations'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

window.GLOBALS = JSON.parse(document.getElementById('GLOBALS').textContent)

const store = new Vuex.Store({
    state: {
        snackbar: false,
        snackbarText: '',
        snackbarColor: 'green',
        user: window.GLOBALS.user,
        loading: false,
        currentThonYear: null
    },
    mutations: {
      showAlert (state, { message, status }) {
          state.snackbarText = message
          // status can be 'success', 'error', 'warning', 'info'
          switch (status) {
            case 'success':
              state.snackbarColor = 'green'
              break
            case 'error':
              state.snackbarColor = 'red'
              break
            case 'warning':
              state.snackbarColor = 'orange'
              break
            case 'info':
              state.snackbarColor = 'thon-blue'
              break
            default:
              state.snackbarColor = status
          }
          state.snackbar = true
      },
      
      setCurrentThonYear (state, { year }) {
          state.currentThonYear = year
      },
    },
    actions: actions,
    getters: getters,
    strict: debug
})

export default store
