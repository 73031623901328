import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/admin',
			name: 'manage',
			component: () => import('@/components/admin/Manage'),
        },
		{
			path: '/admin/photographers',
			name: 'manage-photographers',
			component: () => import('@/components/admin/Photographers'),
		},
		{
			path: '/',
			name: 'galleries',
			component: () => import('@/components/gallery/GalleryList'),
        },
		{
			path: '/photographers',
			name: 'photographers',
			component: () => import('@/components/photographer/PhotographerList'),
        },
		{
			path: '/gallery/:thon_year/:slug',
			component: () => import('@/components/gallery/Gallery'),
			props: true
        },
		{
			path: '/photographer/:slug',
			component: () => import('@/components/photographer/Photographer'),
			props: true
        },
	],
});
