<template>
  <v-app>
    <v-app-bar
        fixed
        color="thon-blue"
        dark
        elevate-on-scroll
        scroll-target="#main"
        style="height:72px"
        >
        <a href="http://thon.org">
        <img src='https://post.thon.org/static/frontend_prod/img/thon_white.f9363270.png' height='30px'  class='ml-2'/>
        </a>
        <router-link to="/" style="text-decoration: none; color: white;">
            <v-toolbar-title class='ml-2'>
                Photos
            </v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <v-menu v-if="!isMobile">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                light
                color="white"
                v-bind="attrs"
                v-on="on"
                rounded
                class="ml-2"
                >
                    {{ currentThonYear == null ? "THON Year" : currentThonYear }}
                    <v-icon light right small color="black">
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </template>
            <v-list  class="pa-0 text-center">
                <v-list-item
                    v-for="(item, index) in thonYears"
                    :key="index"
                >
                    <v-btn depressed rounded @click="updateThonYear(item)">{{ item }}</v-btn>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn v-if="!isMobile" to="/" color="white" rounded class="ma-2" light>
            Events
        </v-btn>
        <v-btn v-if="!isMobile" to="/photographers" color="white" rounded class="ma-2" light>
            Photographers
        </v-btn>
        <v-btn v-if="!isMobile" color="thon-gold" rounded class="ma-2" href="https://donate.thon.org/" light large>
            DONATE NOW
        </v-btn>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isMobile"></v-app-bar-nav-icon>
    </v-app-bar>
    <!-- Navigation Drawer for Mobile -->
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item @click="drawer = false">
          <v-btn to="/">Events</v-btn>
        </v-list-item>
        <v-list-item @click="drawer = false">
          <v-btn to="/photographers">Photographers</v-btn>
        </v-list-item>
        <v-list-item @click="drawer = false">
          <v-btn color="thon-gold" href="https://donate.thon.org">DONATE NOW</v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-sheet id="main" style="margin-top: 72px;">
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
      v-if="$store.state.loading"
    ></v-progress-linear>
      <v-content>
            <router-view>
            </router-view>
            <notification />
      </v-content>
    </v-sheet>
    <v-card class="thon-gold rounded-0">
    <v-card-title class="text--center">
    Photos from THON 2020, 2021, and 2022 are on photos1.THON.org
    <v-spacer/><v-btn rounded depressed href="https://photos1.thon.org" target="_blank">View Older Photos</v-btn>
    </v-card-title>
    </v-card>
    <ThonFooter/>
    <v-snackbar
      v-model="$store.state.snackbar"
      :color="$store.state.snackbarColor"
      :timeout="5000"
    >
      {{ $store.state.snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="$store.state.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!--
    <v-main id="#scrolling-techniques-7">
        <v-container fluid >
            <router-view>
            </router-view>
            <notification />
        </v-container>
    </v-main>-->
  </v-app>
</template>

<script>
import {
    mapState
} from 'vuex';
import Notification from './components/Notification.vue';
import ThonFooter from './components/ThonFooter.vue';

export default {
  data() {
    return{
        thonYears: [],
        drawer: false,
        isMobile: false

    }
  },
  components: { Notification, ThonFooter },
  computed: mapState(['currentThonYear']),
  methods: {
    async fetchGalleryYears() {
        const { data } = await this.$http.get(this.$urls['api:gallery_year_list']())
        this.thonYears = data
    },
    updateThonYear(year) {
        console.log("hey im clicking!!!")
        this.$store.commit('setCurrentThonYear', { year })
    },
    handleResize() {
      this.isMobile = window.innerWidth < 960; // Example breakpoint
    },
  },
  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.fetchGalleryYears()
  }
}
</script>

<style lang="scss">
#app {
    font-family: 'Cabin', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /*   margin-top: 60px;
 */

    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    padding: 0;
    margin: 0;
}

.html {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.content-container {
    flex: 1 0 auto;
}

.alert {
    position: fixed;
    top: 1rem;
    left: 2%;
    width: 96%;
    z-index: 9999;
    border-radius: 5px
}

@import url('https://fonts.googleapis.com/css?family=Cabin&display=swap');

body {
    margin: 0 auto;
    width: 100%;
    /*max-width: 1240px;*/
    font-family: 'Unica One', sans-serif;
    letter-spacing: .1em;
    position: relative;
    min-height: 100%;
    background: #FFF imp !important;
}

/* Classes automatically wrapped in a vue transition */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity .1s;
}

.fade-leave {
    opacity: 1;
}

.fade-leave-active {
    transition: opacity .75s;
    opacity: 0;
}


.dash-alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-radius: .75em;
    font-size: 18px;
    border: 3px solid #d0e9c6;
    padding: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.icon-holder-alert-success {
    background-color: #d0e9c6;
    color: #3c763d;
    border-radius: .3em;
    font-size: 18px;
    border: 3px solid #d0e9c6;
    padding: 0;
    height: 100%;
}

/* Danger Alerts */
.dash-alert-danger {
    background-color: #f2dede;
    color: #a94442;
    border-radius: .75em;
    font-size: 18px;
    border: 3px solid #ebcccc;
    padding: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.icon-holder-alert-danger {
    background-color: #ebcccc;
    color: #a94442;
    border-radius: .3em;
    font-size: 18px;
    border: 3px solid #ebcccc;
    padding: 0;
    height: 100%;
}

.dash-alert-danger hr {
    border-top-color: #e4b9b9;
}

.post-alert {
    left: 3.5%;
    width: 93%;
}

[v-cloak] {
  visibility: hidden;
  
}

.v-toolbar__content {
    height: 72px !important;
}
.v-btn {
    text-transform: unset !important;
}
</style>

<style scoped>
</style>
